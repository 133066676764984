<template>
  <!-- Version: 1.2 -->
  <h2>1. Game Description</h2>
  <p>
    The Highlight Games IIHF World Championship Game is a unique software simulation of randomly selected ice hockey
    highlights from matches to create gaming opportunities.
  </p>
  <h2>2. Game Description and Length</h2>
  <p>
    A single round of an electronic betting game lasts 5 minutes on average, consisting of the fixture announcements for
    that game, display, and results. A selection of highlight ice hockey events is statistically independent, random,
    and unpredictable, and generated by the Random Number Generator – RNG.
  </p>
  <h2>3. Betting Markets</h2>
  <h3>3.1 Exact Goals (Including Overtime)</h3>
  <p>
    The meaning of Exact Number of Goals Betting is that an exact number of goals must be scored in a single match. So,
    this betting variation means that you are to predict a precise number of goals despite a final outcome of a whole
    match.
  </p>
  <h3>3.2 Home Team Exact Goals (Including Overtime)</h3>
  <p>A bet on the exact number of goals that the home team will score in the game.</p>
  <h3>3.3 Away Team Exact Goals (Including Overtime)</h3>
  <p>A bet on the exact number of goals that the away team will score in the game.</p>
  <h3>3.4 Both Teams to Score (Regulation Time)</h3>
  <p>
    To win a BTTS bet, both teams need to score at least one goal, making any result from 1-1 and upwards, a winning
    bet. The “Both Teams to Score” bet gives punters the chance to take advantage of such events, in order to get
    profitable odds, when only one of the two teams has managed to score.
  </p>
  <h3>3.5 Correct Score (Including Overtime)</h3>
  <p>
    Correct score gambling is betting on what you think the final outcome of the ice hockey match will be with a maximum
    of 5 goals in a game there are 18 possible outcomes.
  </p>
  <h3>3.6 Match Result (1X2) (Regulation Time)</h3>
  <p>A bet on home team winning (1), away team winning (2) or match ending with a draw (X).</p>
  <h3>3.7 Match Result (1,2) (Including Overtime)</h3>
  <p>A bet on home team winning (1), away team winning (2).</p>
  <h3>3.8 Match Result and Over/Under 3.5 Goals (Regulation Time)</h3>
  <p>A bet on the result of the match and the total number of goals to be under or over 3.5 goals.</p>
  <h3>3.9 Match Result and Over/Under 3.5 Goals (Including Overtime)</h3>
  <p>A bet on the result of the match and the total number of goals to be under or over 3.5 goals.</p>
  <h3>3.10 Over/Under 2.5 Home or Away Team Goals (Including Overtime)</h3>
  <p>A bet that the Home Team or the Away Team will score either more or less than 2.5 goals.</p>
  <h3>3.11 Over 2.5, 3.5, 4.5 Total Goals (Including Overtime)</h3>
  <p>A bet on the total number of goals scored by both teams to be over 2.5, 3.5 or 4.5 in the match.</p>
  <h3>3.12 Game Lines 2-way (Regulation Time)</h3>
  <p>
    A betting line with the odds set to determine the favourite or underdog in the match. This handicap creates a
    &#39;half goal&#39; handicap line between the 2 teams, where there are 2 possible outcomes.
  </p>
  <h3>3.13 Game Lines 3-way (Regulation Time)</h3>
  <p>
    A betting line with the odds set to determine the favourite or underdog in the match. This handicap creates a
    &#39;whole goal&#39; handicap line between the 2 teams, where there are 3 possible outcomes (i.e., including a tie).
  </p>
  <h3>3.14 Highest Scoring Period Lines (Regulation Time)</h3>
  <p>
    A bet on which period with have the most goals scored in it. This can be Period 1, Period 2, Period 3, or a Tie.
  </p>
  <h3>3.15 Team to Win Most Periods (Regulation Time)</h3>
  <p>A bet on which team will win the most periods. This can be the Home Team, Away Team, or a Tie.</p>
  <h3>3.16 Which Team will score first? (Including Overtime)</h3>
  <p>A bet on which team will score first in the game?</p>
  <h2>4.</h2>
  <p>The average RTP for this game is 86.30%.</p>
</template>
